
import Work1 from "../../assets/work1.jpg"
import Work2 from "../../assets/work2.jpg"
import Work3 from "../../assets/work3.jpg"
import Work4 from "../../assets/work4.jpg"
import Work5 from "../../assets/work5.jpg"

const projectData = [
    {
        id:1,
        image: Work1,
        title:"Web Design",
        category: "web",
        href: "https://www.google.com/"
    },
    {
        id:2,
        image: Work2,
        title:"App Movil",
        category: "app",
        href: "https://weather-app-9eb.pages.dev/"
    },
    {
        id:3,
        image: Work3,
        title:"Web Design",
        category: "web",
        href:"https://www.linkedin.com/in/anmol-kaloni-2526b81b8/"
    },
    {
        id:4,
        image: Work4,
        title: "App Movil",
        category: "app",
        href: "https://note-verse.pages.dev/"
    },
    {
        id:5,
        image: Work5,
        title: "Brand Design",
        category: "design",
        href:"https://pro-code.pages.dev/"
    }
]

const projectNav = [
    {
        name: "all"
    },
    {
        name: "web"
    },
    {
        name: "app"
    },
    {
        name: "design"
    }
]


export default projectData;
export {projectNav};